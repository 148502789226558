// The key is capitalized to be compatible with http://www.w3.org/TR/DOM-Level-3-Events-code.

// Theoretically the properties should be named as keyCode (numeric) and code (string) to be
// aligned with KeyboardEvent#keyCode and KeyboardEvent#code, but that would be confusing for people
// that are not familiar with this story, and when KeyboardEvent#code is supported by the browser,
// this component should be deprecated altogether, so let's use easy-to-understand property names
// here.

/**
 * Look up keyCode (KeyboardEvent#keyCode, numeric) with keyName (KeyboardEvent#code, string).
 * @example   keyCode.Tab === 9
 * @return    {number}  The keyCode (KeyboardEvent#keyCode, numeric)
 */
/* eslint-disable quote-props */
export const keyCode = {
  // Alpha-numeric
  'Digit1': 0x31, // 49
  'Digit2': 0x32, // 50
  'Digit3': 0x33, // 51
  'Digit4': 0x34, // 52
  'Digit5': 0x35, // 53
  'Digit6': 0x36, // 54
  'Digit7': 0x37, // 55
  'Digit8': 0x38, // 56
  'Digit9': 0x39, // 57
  'Digit0': 0x30, // 48
  'KeyA': 0x41, // 65
  'KeyB': 0x42, // 66
  'KeyC': 0x43, // 67
  'KeyD': 0x44, // 68
  'KeyE': 0x45, // 69
  'KeyF': 0x46, // 70
  'KeyG': 0x47, // 71
  'KeyH': 0x48, // 72
  'KeyI': 0x49, // 73
  'KeyJ': 0x4A, // 74
  'KeyK': 0x4B, // 75
  'KeyL': 0x4C, // 76
  'KeyM': 0x4D, // 77
  'KeyN': 0x4E, // 78
  'KeyO': 0x4F, // 79
  'KeyP': 0x50, // 80
  'KeyQ': 0x51, // 81
  'KeyR': 0x52, // 82
  'KeyS': 0x53, // 83
  'KeyT': 0x54, // 84
  'KeyU': 0x55, // 85
  'KeyV': 0x56, // 86
  'KeyW': 0x57, // 87
  'KeyX': 0x58, // 88
  'KeyY': 0x59, // 89
  'KeyZ': 0x5A, // 90

  // Punctuation
  'Comma': 0xBC, // 188
  'Period': 0xBE, // 190
  'Semicolon': 0xBA, // 186
  'Quote': 0xDE, // 222
  'BracketLeft': 0xDB, // 219
  'BracketRight': 0xDD, // 221
  'Backquote': 0xC0, // 192
  'Backslash': 0xDC, // 220
  'Minus': 0xBD, // 189
  'Equal': 0xBB, // 187
  'IntlRo': 0xC1, // 193
  'IntlYen': 0xFF, // 255

  // Modifiers
  'AltLeft': 0x12, // 18
  'AltRight': 0x12, // 18
  'CapsLock': 0x14, // 20
  'ControlLeft': 0x11, // 17
  'ControlRight': 0x11, // 17
  'OSLeft': 0x5B, // 91
  'OSRight': 0x5C, // 92
  'ShiftLeft': 0x10, // 16
  'ShiftRight': 0x10, // 16

  // Non-printable
  'ContextMenu': 0x5D, // 93
  'Enter': 0x0D, // 13
  'Space': 0x20, // 32
  'Tab': 0x09, // 9
  'Delete': 0x2E, // 46
  'End': 0x23, // 35
  'Home': 0x24, // 36
  'Insert': 0x2D, // 45
  'PageDown': 0x22, // 34
  'PageUp': 0x21, // 33
  'ArrowDown': 0x28, // 40
  'ArrowLeft': 0x25, // 37
  'ArrowRight': 0x27, // 39
  'ArrowUp': 0x26, // 38
  'Escape': 0x1B, // 27
  'PrintScreen': 0x2C, // 44
  'ScrollLock': 0x91, // 145
  'Pause': 0x13, // 19

  // Functional
  'F1': 0x70, // 112
  'F2': 0x71, // 113
  'F3': 0x72, // 114
  'F4': 0x73, // 115
  'F5': 0x74, // 116
  'F6': 0x75, // 117
  'F7': 0x76, // 118
  'F8': 0x77, // 119
  'F9': 0x78, // 120
  'F10': 0x79, // 121
  'F11': 0x7A, // 122
  'F12': 0x7B, // 123
  'F13': 0x7C, // 124
  'F14': 0x7D, // 125
  'F15': 0x7E, // 126
  'F16': 0x7F, // 127
  'F17': 0x80, // 128
  'F18': 0x81, // 129
  'F19': 0x82, // 130
  'F20': 0x83, // 131
  'F21': 0x84, // 132
  'F22': 0x85, // 133
  'F23': 0x86, // 134
  'F24': 0x87, // 135

  // NumPad with NumLock on
  'NumLock': 0x90, // 144
  'Numpad0': 0x60, // 96
  'Numpad1': 0x61, // 97
  'Numpad2': 0x62, // 98
  'Numpad3': 0x63, // 99
  'Numpad4': 0x64, // 100
  'Numpad5': 0x65, // 101
  'Numpad6': 0x66, // 102
  'Numpad7': 0x67, // 103
  'Numpad8': 0x68, // 104
  'Numpad9': 0x69, // 105
  'NumpadAdd': 0x6B, // 107
  'NumpadComma': 0xC2, // 194
  'NumpadDecimal': 0x6E, // 110
  'NumpadDivide': 0x6F, // 111
  'NumpadEnter': 0x0D, // 13
  'NumpadEqual': 0x0C, // 12
  'NumpadMultiply': 0x6A, // 106
  'NumpadSubtract': 0x6D, // 109

  // NumPad with NumLock off
  'Numpad0-Insert': 0x2D, // 45
  'Numpad1-End': 0x23, // 35
  'Numpad2-ArrowDown': 0x28, // 40
  'Numpad3-PageDown': 0x22, // 34
  'Numpad4-ArrowLeft': 0x25, // 37
  'Numpad5-Clear': 0x0C, // 12
  'Numpad6-ArrowRight': 0x27, // 39
  'Numpad7-Home': 0x24, // 36
  'Numpad8-ArrowUp': 0x26, // 38
  'Numpad9-PageUp': 0x21, // 33
  'NumpadDecimal-Delete': 0x2E, // 46
};
/* eslint-enable quote-props */
