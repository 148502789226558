import _ from 'underscore';
import { keyCode } from './keyCode';

// The key is capitalized to be compatible with http://www.w3.org/TR/DOM-Level-3-Events-code.

// Generate a readonly output.
// Theoretically the properties should be named as keyCode (numeric) and code (string) to be
// aligned with KeyboardEvent#keyCode and KeyboardEvent#code, but that would be confusing for people
// that are not familiar with this story, and when KeyboardEvent#code is supported by the browser,
// this component should be deprecated altogether, so let's use easy-to-understand property names
// here.

const uniqueValues = object => _.object(_.unique(_.pairs(object), o => o[1]));

/**
 * Look up keyName (KeyboardEvent#code, string) with keyCode (KeyboardEvent#keyCode, numeric).
 * @example   keyName[9] === 'Tab'
 * @return    {string}  The name (KeyboardEvent#code, string)
 */
export const keyName = Object.create(
  Object.prototype,
  _.mapObject(_.invert(uniqueValues(keyCode)), val => ({
    enumerable: true,
    value: val,
    writable: false,
  }))
);
