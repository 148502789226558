/* eslint-disable */
import _ from 'underscore';

function initialize(projectId) {
  try {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
      t = l.createElement(r); t.async = 1; t.src = `https://www.clarity.ms/tag/${i}`;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    }(window, document, 'clarity', 'script', projectId));
  } catch (all) { }
}

export function setClarityTag(tags) {
  if (window.clarity) {
    _.each(_.keys(tags), (key) => {
      const tagValue = tags[key];
      if (tagValue) {
        window.clarity('set', key, _.isString(tagValue)? tagValue: JSON.stringify(tagValue));
      }
    });
  }
}

export function setupClarity(projectId, tags = {}, userId, sessionId, pageId) {
  initialize(projectId);

  setClarityTag(tags);

  if (userId || sessionId || pageId) {
    window.clarity('identify', userId, sessionId, pageId);
  }
}
